import React, { useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import {hasAValue} from "../../uitls/SharedFunctions";
import {useParams} from "react-router-dom";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {IoMdCloseCircle} from "react-icons/io";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';
import { getCategoriesCall } from '../../api/ApiCalls';
import { useQuery } from '../../hooks/useQuery';


export default function FilterCityStoreDiscounts({ initStrings, filters, updateFilters }) {
    const citiesRS = useSelector((state) => state.citiesRS);
    const { city } = useParams();
    const [activeIndex, setActiveIndex] = useState(null);
    const [categories, setCategories] = useState([]);
    const handleLinkClick = useHandleLinkClick();
    const params = useQuery();
    const category = params.get("category");

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const filterItems = [
        {
            title: stringsTranslate(appWords.netherlands),
            content: citiesRS.filter(item => item.country_id === 1),
        },
        {
            title: stringsTranslate(appWords.belgium),
            content: citiesRS.filter(item => item.country_id === 2),
        }

    ];

    useEffect(() => {
        getCategories()
    },[]);

    useEffect(() => {
        if (hasAValue(city)) {
            const foundCity = citiesRS.find((item) =>
                item.name.toString().toLowerCase().includes(city.toString().toLowerCase())
            );
            if (foundCity && filters.city?.name !== foundCity.name) {
                updateFilters({
                    city: foundCity,
                });
            }
        }
        if (hasAValue(category) && filters.category !== category) {
            updateFilters({
                category: Number(category) ,
            }
            );
        }
    }, [city, category]);


    useEffect(() => {
        let link = '/studentenkorting';
        if (filters.city?.name) {
            link += '/' + filters.city.name;
        }
        if (hasAValue(filters.category)) {
            link += `?category=${filters.category}`;
        }
        handleLinkClick(link);
    }, [filters.city?.name, filters.category]);


    const getCategories = () => {
        getCategoriesCall().then((res) => {
            setCategories(res);
        });
    };
    return (
        <>
            {
                filters.city&& <div className="d-flex py-2 my-2 align-items-center filter-badge-text">
                <a className="badge badge-pill filter-badge-body p-2">
                    <IoMdCloseCircle className="mr-2" onClick={() =>
                        updateFilters({
                            city: null,
                        })
                    }/>
                    {filters.city.name}
                </a>
            </div>
            }

                <h4 className="my-3">{ initStrings.categories }</h4>
                <div className="W-100 mb-4 mt-3">
                    <div className="cursor-pointer" >
                        <div className="filter-city-accordion-item-body-md active">
                            
                            <label className={"w-100 my-2 cursor-pointer"} >
                                <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                       type="radio"
                                       checked={!filters.category}
                                       onClick={() =>
                                           updateFilters({
                                               category: null,
                                           })
                                       }
                                /> {initStrings.all_categories}
                            </label>
                            {categories.map((item, i) => {
                                const checked = hasAValue(filters.category)
                                    ? item.id.toString().includes(filters.category)
                                    : false;

                                return (
                                    <label className={"w-100 my-2 cursor-pointer"} key={i}>
                                        <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                               type="radio" checked={checked}
                                               onClick={() =>
                                                   updateFilters({
                                                       category: checked ? null : item.id,
                                                   })
                                               }
                                        /> {item.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
            </div>

            <h4 className="my-3">{initStrings.sectionFilterHeading}</h4>
            <div className="W-100 ">
                {filterItems.map((item, index) => (
                    <div className="cursor-pointer" key={index}>
                        <div
                            className={"" + (index === activeIndex ? "active  d-flex justify-content-between py-2" : "d-flex justify-content-between  pb-2")}
                            onClick={() => handleAccordionClick(index)}>
                            <h5 className={"filter-city-accordion-item-header"}>{item.title}</h5>
                            {index === activeIndex ?
                                <MdKeyboardArrowUp className={"text-primary h5"}></MdKeyboardArrowUp> :
                                <MdKeyboardArrowDown className={"text-primary h5"}></MdKeyboardArrowDown>
                            }

                        </div>
                        <div
                            className={"filter-city-accordion-item-body-md " + (index === activeIndex ? "active" : "")}>
                            {item.content.map((item, i) => {
                                const checked = hasAValue(filters.city)
                                    ? item.name.toString().includes(filters.city.name)
                                    : false;

                                return (
                                    <label className={"w-100 my-2 cursor-pointer"} key={i}>
                                        <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                               type="radio" checked={checked}
                                               onClick={() =>
                                                   updateFilters({
                                                       city: checked ? null : item,
                                                   })
                                               }
                                        /> {item.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
